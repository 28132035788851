import React from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import Layout from '../layout/layout'
import { Link } from 'gatsby'
import Spinner from '../components/spinner';
import SEO from "../layout/seo"
import HubspotForm from 'react-hubspot-form'
import './styles/register.scss'

const pageContent = (<>
        
        <div style={{
            minHeight: `470px`
        }}>
            <HubspotForm
                portalId="23545599"
                formId='17327bdd-305c-4fb7-92f5-9cc63b5ca218'
                loading={<Spinner />}
            />
        </div>

    </>)


const register = () => {
    
    
    return (

        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <>
                    { modal ? (
                        <main><Link className="closeIcon" to={closeTo}><span>Close</span></Link>
                            <div id="register-page">
                                <h2 id="register-page-title">Ignite Summit 2024<br/><span className="turquoise">A Free, Virtual Event</span></h2>
                                <p>On June 11, join Apache Ignite developers and architects to share information and best practices.</p>
                                {pageContent}
                            </div>

                            <div id="register-complete-page" className="hidden">
                                <h2><span className="turquoise">Registration Complete!</span><br/>See you on June 11, 2024</h2>

                                <div class="marketoEmbedForm">
                                    <div class="mktoForm" style={{marginTop: 0 + 'px'}}>
                                        <p style={{marginBottom: 15 + 'px'}}>In the meantime, visit websites for <a href="https://ignite.apache.org/" target="_blank" className="turquoise">Apache Ignite</a> and for Ignite Summit's organizer <a href="https://www.gridgain.com/" target="_blank" className="turquoise">GridGain</a>. Learn about key Ignite features, download the latest Ignite release, and join the community email forum. And, to stay tuned, subscribe to the <a href="https://twitter.com/apacheignite" target="_blank" className="turquoise">Apache Ignite Twitter</a> or <a href="https://www.linkedin.com/showcase/apache-ignite/" target="_blank" className="turquoise">LinkedIn page</a>.</p>
                                        <p>Between now and June 11, you will receive updates about the Ignite Summit speakers and sessions.</p>
                                    </div>
                                </div>
                            </div>
                        </main>
                    ) : (
                        <Layout attr={"page register"}>
                            <SEO title="Ignite Summit 2022 Registration" description="Register for Apache Ignite Summit 2024 virtual event." />
                            <div class="ReactModal__Content">
                                <div className="container register-page" id="register-page">
                                    <h2 id="register-page-title">Ignite Summit 2024<br/><span className="turquoise">A Free, Virtual Event</span></h2>
                                <p>On June 11, join Apache Ignite developers and architects to share information and best practices.</p>
                                    {pageContent}
                                </div>
                            </div>
                        </Layout>
                    )}
                </>
            )}
            
        </ModalRoutingContext.Consumer>
    )
}

export default register
